<template>
  <div class="bg">
    <div class="countdown-container">
      <div class="countdown-el days-c">
          <p class="big-text" id="days">{{ days | two_digits }}</p>
          <span>days</span>
      </div>
      <div class="countdown-el hours-c">
          <p class="big-text" id="hours">{{ hours | two_digits }}</p>
          <span>hours</span>
      </div>
      <div class="countdown-el mins-c">
          <p class="big-text" id="mins">{{ minutes | two_digits }}</p>
          <span>mins</span>
      </div>
      <div class="countdown-el seconds-c">
          <p class="big-text" id="seconds">{{ seconds | two_digits }}</p>
          <span>seconds</span>
      </div>
    </div>
  </div>
  <!-- <img src="./assets/portrait_photo.jpg" style="height: 100%; size: cover; position: center;"/> -->
  <!-- <div id="app">
    <img alt="Vue logo" src="./assets/portrait_photo.jpg" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div> -->
  
</template>

<script>
//import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    //HelloWorld,
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      portrait: false,
      image: 'url("./assets/portrait_photo_2.png")'
    };
  },
  computed: {
    date() {
      return Math.trunc(Date.parse("28 Aug 2021 17:00:00") / 1000);
    },
    seconds() {
      return ((this.date - this.now) % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
    },
    minutes() {
      return (Math.trunc((this.date - this.now) / 60) % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
    },
    hours() {
      return (Math.trunc((this.date - this.now) / 60 / 60) % 24).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
    },
    days() {
      return (Math.trunc((this.date - this.now) / 60 / 60 / 24)).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
    },
  },
  beforeCreate() {
    document.title = 'LuViSta';
  },
  mounted() {
    setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
    // window.addEventListener("orientationchange", this.handleOrientationChange);
    // if (window.screen.orientation.type == "portrait-primary") {
    //   console.log("Portrait mode");
    //   this.portrait = true;
    //   console.log(this.portrait);
    // } else if (window.screen.orientation.type == "landscape-primary") {
    //   console.log("Landscape mode");
    //   this.portrait = false;
    //   console.log(this.portrait);
    // }
  },
  methods: {
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
        console.log("Portrait mode");
      } else if (orientation === "landscape-primary") {
        // landscape mode
        console.log("Landscape mode");
      }
    },
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body,
html {
  height: 100%;
  margin: 0;
}

@media all and (orientation:portrait) {
  .bg {
    /* The image used */
    background-image: url("./assets/portrait_photo_2.png");

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  h1 {
    font-weight: normal;
    font-size: 4rem;
    margin-top: 5rem;
  }

  .countdown-container {
      /* display: flex;
      flex-wrap: wrap;
      justify-content: center;
      bottom: 0; */
      position: fixed; 
      top: 83%;
      left: 50%;
    /* margin-top: -50px; */
      margin-left: -25.0vw;
      bottom: 0;
      width:50vw; /* or whatever width you want. */
      height: 50px;
      max-width:50vw; /* or whatever width you want. */
    /* display: inline-block; */
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }

  .big-text {
      font-weight: bold;
      font-size: 6vw;
      line-height: 1;
      margin: 1vw 2vw -0.5vw;
  }

  .countdown-el {
      text-align: center;
      color: white;
  }

  .countdown-el span {
      font-size: 3vw;
  }
}
@media all and (orientation:landscape) {
  .bg {
    /* The image used */
    background-image: url("./assets/landscape_photo_2.png");

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  h1 {
    font-weight: normal;
    font-size: 4rem;
    margin-top: 5rem;
  }

  .countdown-container {
      /* display: flex;
      flex-wrap: wrap;
      justify-content: center;
      bottom: 0; */
      position: fixed; 
      top: 83%;
      left: 50%;
    /* margin-top: -50px; */
      margin-left: -25.0vw;
      bottom: 0;
      width:50vw; /* or whatever width you want. */
      height: 50px;
      max-width:50vw; /* or whatever width you want. */
    /* display: inline-block; */
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }

  .big-text {
      font-weight: bold;
      font-size: 2.5vw;
      line-height: 1;
      margin: 1vw 2vw -0.5vw;
  }

  .countdown-el {
      text-align: center;
      color: white;
  }

  .countdown-el span {
      font-size: 1vw;
  }
}



@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");

/* * {
    box-sizing: border-box;
} */
/* 
body {
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    font-family: "Poppins", sans-serif;
    margin: 0;
} */


</style>
